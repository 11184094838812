export default [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "LoginView",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePasswordView",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ "../views/ChangePasswordView.vue"
      ),
  },

  // ------------ MENU LATERAL ------------
  {
    path: "/home",
    name: "HomeView",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/user-profile",
    name: "UserProfileView",
    component: () =>
      import(
        /* webpackChunkName: "user-profile" */ "../views/UserProfileView.vue"
      ),
  },
  {
    path: "/campaigns",
    name: "CampaignsView",
    component: () =>
      import(/* webpackChunkName: "campaigns" */ "../views/CampaignsView.vue"),
  },
  {
    path: "/leads",
    name: "LeadsView",
    component: () =>
      import(/* webpackChunkName: "leads" */ "../views/LeadsView.vue"),
  },
  {
    path: "/lead-report",
    name: "LeadReportView",
    component: () =>
      import(
        /* webpackChunkName: "lead-report" */ "../views/LeadsReportView.vue"
      ),
  },
  {
    path: "/leads/:id/interactions",
    name: "LeadsViewInteraction",
    component: () =>
      import(
        /* webpackChunkName: "leads-interactions" */ "../views/LeadsViewInteraction.vue"
      ),
  },
  {
    path: "/resale",
    name: "ResaleView",
    component: () =>
      import(/* webpackChunkName: "resale" */ "../views/ResaleView.vue"),
  },
  {
    path: "/system-configs/regional",
    name: "RegionalView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-regional" */ "../views/SystemConfigs/RegionalView.vue"
      ),
  },
  {
    path: "/system-configs",
    name: "SystemConfigsView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs" */ "../views/SystemConfigsView.vue"
      ),
  },
  // ------------ SECURITY ------------
  {
    path: "/system-configs/user",
    name: "UserView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-user" */ "../views/SystemConfigs/UserView.vue"
      ),
  },
  {
    path: "/system-configs/group",
    name: "GroupView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-group" */ "../views/SystemConfigs/GroupView.vue"
      ),
  },
  {
    path: "/system-configs/group-transaction",
    name: "GroupTransactionView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-group-transaction" */ "../views/SystemConfigs/GroupTransactionView.vue"
      ),
  },
  {
    path: "/system-configs/user-group",
    name: "UserGroupView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-user-group" */ "../views/SystemConfigs/UserGroupView.vue"
      ),
  },
  {
    path: "/system-configs/user-log-access",
    name: "UserLogAccessView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-user-log-access" */ "../views/SystemConfigs/UserLogAccessView.vue"
      ),
  },
  {
    path: "/system-configs/user-log-activity",
    name: "UserLogActivityView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-user-log-activity" */ "../views/SystemConfigs/UserLogActivityView.vue"
      ),
  },
  {
    path: "/system-configs/permission-user-lead",
    name: "PermissionUserLeadView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-permission-user-lead" */ "../views/SystemConfigs/PermissionUserLeadView.vue"
      ),
  },
  {
    path: "/system-configs/permission-user-lead-user",
    name: "PermissionUserLeadUserView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-permission-user-lead" */ "../views/SystemConfigs/PermissionUserLeadUserView.vue"
      ),
  },
  // ------------ MASTERDATA ------------
  {
    path: "/system-configs/location-type",
    name: "LocationTypeView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-location-type" */ "../views/SystemConfigs/LocationTypeView.vue"
      ),
  },
  {
    path: "/system-configs/origin-type",
    name: "OriginTypeView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-origin-type" */ "../views/SystemConfigs/OriginTypeView.vue"
      ),
  },
  {
    path: "/system-configs/rival",
    name: "RivalView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-rival" */ "../views/SystemConfigs/RivalView.vue"
      ),
  },
  {
    path: "/system-configs/address",
    name: "AddressView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-address" */ "../views/SystemConfigs/AddressView.vue"
      ),
  },
  {
    path: "/system-configs/origin",
    name: "OriginView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-origin" */ "../views/SystemConfigs/OriginView.vue"
      ),
  },
  {
    path: "/system-configs/business-days",
    name: "BusinessDaysView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-business-days" */ "../views/SystemConfigs/BusinessDaysView.vue"
      ),
  },
  // ------------ APPROVAL FLOW ------------
  {
    path: "/system-configs/approval-flow",
    name: "ApprovalFlowView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-approval-flow" */ "../views/SystemConfigs/ApprovalFlowView.vue"
      ),
  },
  {
    path: "/system-configs/approval-flow-level",
    name: "ApprovalFlowLevelView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-approval-flow-level" */ "../views/SystemConfigs/ApprovalFlowLevelView.vue"
      ),
  },
  {
    path: "/system-configs/communication-script-type",
    name: "CommunicationScriptTypeView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-communication-script-type" */ "../views/SystemConfigs/CommunicationScriptTypeView.vue"
      ),
  },
  {
    path: "/system-configs/communication-script",
    name: "CommunicationScriptView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-communication-script" */ "../views/SystemConfigs/CommunicationScriptView.vue"
      ),
  },
  {
    path: "/system-configs/sales-lead-flow",
    name: "SalesLeadFlowView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-sales-lead-flow" */ "../views/SystemConfigs/SalesLeadFlowView.vue"
      ),
  },
  {
    path: "/system-configs/lead-flow-step",
    name: "LeadFlowStepView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-lead-flow-step" */ "../views/SystemConfigs/LeadFlowStepView.vue"
      ),
  },
  {
    path: "/system-configs/lead-flow-step-communication-script",
    name: "LeadFlowStepCommunicationScriptView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-lead-flow-step-communication-script" */ "../views/SystemConfigs/LeadFlowStepCommunicationScriptView.vue"
      ),
  },
  {
    path: "/system-configs/contact-method",
    name: "ContactMethodView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-contact-method" */ "../views/SystemConfigs/ContactMethodView.vue"
      ),
  },
  {
    path: "/system-configs/lead-flow-step-detail",
    name: "LeadsFlowStepDetailView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-leads-flow-step-detail" */ "../views/SystemConfigs/LeadFlowStepDetailView.vue"
      ),
  },

  // ------------ LEADS ------------
  {
    path: "/system-configs/regional-resale-campaign",
    name: "RegionalResaleCampaignView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-regional-resale-campaign" */ "../views/SystemConfigs/RegionalResaleCampaignView.vue"
      ),
  },
  {
    path: "/system-configs/lead-interaction",
    name: "LeadsInteractionView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-leads-interaction" */ "../views/SystemConfigs/LeadInteractionView.vue"
      ),
  },
  {
    path: "/system-configs/facebook",
    name: "FacebookView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-facebook" */ "../views/SystemConfigs/FacebookView.vue"
      ),
  },
  {
    path: "/system-configs/campaign-type",
    name: "CampaignTypeView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-campaign-type" */ "../views/SystemConfigs/CampaignTypeView.vue"
      ),
  },
  {
    path: "/system-configs/lead-interaction-type",
    name: "LeadInteractionTypeView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-lead-interaction-type" */ "../views/SystemConfigs/LeadInteractionTypeView.vue"
      ),
  },
  {
    path: "/system-configs/resale-roleta",
    name: "ResaleRoletaView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-resale-roleta" */ "../views/SystemConfigs/ResaleRoletaView.vue"
      ),
  },
  {
    path: "/system-configs/resale-roleta-particion",
    name: "ResaleRoletaParticionView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-resale-roleta" */ "../views/SystemConfigs/ResaleRoletaParticionView.vue"
      ),
  },
  {
    path: "/system-configs/segmentation-type",
    name: "SegmentationTypeView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-segmentation-type" */ "../views/SystemConfigs/SegmentationTypeView.vue"
      ),
  },
  // ------------ EVENT ------------
  {
    path: "/system-configs/event-approval",
    name: "EventApprovalView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-event-approval" */ "../views/SystemConfigs/EventApprovalView.vue"
      ),
  },
  {
    path: "/system-configs/event-type",
    name: "EventTypeView",
    component: () =>
      import(
        /* webpackChunkName: "system-configs-event-type" */ "../views/SystemConfigs/EventTypeView.vue"
      ),
  },
];
